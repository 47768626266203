import ApiRepository from './ApiRepository'
import AuthRepository from './AuthRepository'
import ApiPublicRepository from './ApiPublicRepository'

const repositories = {
  auth: AuthRepository,
  api: ApiRepository,
  public: ApiPublicRepository
}

export default {
  get: (name) => repositories[name]
}
