<template>
  <footer class="footer">
    <div class="content-xl">
      <div class="footer__line"></div>

      <div class="footer__images">
        <figure>
          <img
            alt="Portal BI"
            class="img-fluid header__portal-bi"
            id="portalBi"
            width="168"
            height="50"
            src="../assets/portal-bi-logo.png">
        </figure>
        <figure>
          <img
            width="200"
            height="60"
            src="../assets/sfc-short.png"
            alt="sfc">
        </figure>
        <figure>
          <img
            width="380"
            height="44"
            src="../assets/minhacienda.png"
            alt="sfc">
        </figure>

      </div>
      <div class="footer__links">
        <p>© 2022 PORTAL BI | Todos los derechos reservados Business Insights</p>
        <a href="/admin">Admin</a>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'Footer'
}
</script>

<style lang="scss" scoped>
@import '../scss/variables';

.footer {
  padding: 20px 0 20px;
  text-align: center;
  margin-top: 100px;

  &__line {
    width: 100%;
    border-top: 1px solid #d2cece;
  }

  &__images {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }

  &__links {
    display: flex;
    align-items: center;
    justify-content: space-between;
    widows: 100%;
    padding: 0 20px;

    p {
      font-size: 12px;
      color: #626262;
    }

    a {
      padding: 8px;
      margin: 0 18px;
      color: $primary-color;
      transition: .4s;

      &:hover {
        color: $primary-color-50;
      }
    }
  }
}
</style>
