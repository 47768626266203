<template>
  <div
    id="app"
    class="app">
    <Header v-if="!isPublicView" />

    <div class="app__content">
      <transition mode="out-in">
        <error
          v-if="globalError.msg"
          key="error"
          :msg="globalError.msg" />
        <div
          v-else-if="isLoading"
          class="app__loading">
          <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
        </div>
        <div v-else>
          <div
            v-if="!isPublicView"
            class="content-xl">
            <div class="app__wrap">
              <a
                alt="Go to the Superintendencia Financiera de Colombia"
                href="/">
                <img
                  alt="Financial Superintendence of Colombia"
                  src="./assets/sfc-logo.png"
                  title="Financial Superintendence of Colombia"
                  width="350"
                  height="70">
              </a>
            </div>
            <div class="app__nav">
              <ul class="list">
                <div class="list__item">
                  <router-link to="/">Inicio</router-link>
                </div>
                <div class="list__item">
                  <router-link to="/reportes">Reportes</router-link>
                </div>
              </ul>
            </div>
          </div>
          <router-view/>
        </div>
      </transition>
    </div>

    <Footer v-if="!isPublicView" />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Footer from './components/Footer.vue'
import Header from './components/Header.vue'

export default {
  name: 'App',
  components: {
    Header,
    Error,
    Footer
  },
  computed: {
    ...mapState([
      'globalError',
      'isLoading',
      'userSession',
      'isPublicView'
    ])
  },
  created () {
    const token = localStorage.getItem('token')
    this.$store.commit('setIsLogged', token)
    if (token) {
      this.$store.dispatch('getUserProfile')
      this.$store.dispatch('getReports')
    }
    this.$store.dispatch('getPublicReports')
  }
}
</script>

<style lang="scss">
@import '@/scss/index.scss';

.app {
  &__content {
    min-height: calc(100vh - 44px - 134px - 171px);
  }

  &__wrap {
    padding: 30px 0;
    text-align: center;
  }

  &__loading {
    min-height: calc(100vh - 100px);
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__nav {
    padding: 6px;
    border-top: 1px solid #d2cece;
    margin: 0;
    margin-bottom: 12px;

    .list {
      display: flex;
      align-items: center;

      &__item {
        margin: 0 16px;
        a {
          color: gray;
        }
      }
    }
  }
}
</style>
