<template>
  <section class="home">
    <div class="home__banner">
      <div class="layer" />
      <div class="content">
        <div class="home__wrap">
          <h1 class="title">Bienvenido al Portal BI</h1>
        </div>
      </div>
    </div>
    <div class="content">
      <div class="home__box">
        <p class="text">
          En este espacio usted podrá encontrar los productos desarrollados en Power BI a los cuales usted tiene acceso.  Para acceder a ellos, diríjase a la pestaña reportes y de click en el menú flotante.
        </p>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Home'
}
</script>

<style lang="scss" scoped>
.home {
  &__banner {
    position: relative;
    background: url('../assets/slider-powerbi.jpg') center no-repeat;
    background-size: cover;

    .layer {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      background: rgba(0,0,0,.5);
    }
  }

  &__box {
    padding: 0 60px;
    margin: 40px 0;
  }

  &__wrap {
    min-height: calc(100vh - 48px - 113px);
    min-height: 650px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 2;
    color: #fff;
  }

  .title {
    text-align: center;
    font-size: 3em;
  }

  .subtitle {
    color: gray;
    margin-top: 30px;
    margin: 0;
  }

  .text {
    color: gray;
    margin-bottom: 16px;
  }
}
</style>
