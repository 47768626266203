export default {
  setLoginUrl (state, payload) {
    state.loginUrl = { ...state.loginUrl, ...payload }
  },
  setGlobalError (state, payload) {
    state.globalError = { ...state.globalError, ...payload }
  },
  setIsLogged (state, payload) {
    state.isLogged = payload
  },
  setIsLoading (state, payload) {
    state.isLoading = payload
  },
  setUserSession (state, payload) {
    state.userSession = payload
  },
  setReports (state, payload) {
    state.reports = { ...state.reports, ...payload }
  },
  setPublicReports (state, payload) {
    state.publicReports = { ...state.publicReports, ...payload }
  },
  setCurrentReports (state, payload) {
    state.currentReports = { ...state.currentReports, ...payload }
  },
  setCurrentPublicReports (state, payload) {
    state.currentPublicReports = { ...state.currentPublicReports, ...payload }
  },
  setIsValidCaptcha (state, payload) {
    state.isValidCaptcha = payload
  },
  setIsPublicView (state, payload) {
    state.isPublicView = payload
  }
}
