<template>
  <nav
    :class="{'menu-reports--active': active}"
    class="nav menu-reports"
    role="navigation">
    <ul class="nav__list">
      <li
        v-for="(itemOneLevel, indexOneLevel) in oneLevel"
        :key="indexOneLevel">
        <input
          :id="`group-${indexOneLevel}`"
          type="checkbox"
          hidden />
        <label :for="`group-${indexOneLevel}`">
          <span class="fa fa-angle-right"></span>
          {{ itemOneLevel.name }}
        </label>
        <ul :class="`group-${indexOneLevel}__list group-list`">
          <li
            v-for="(itemTwoLevel, indexTwoLevel) in itemOneLevel.options"
            :key="indexTwoLevel">
            <input
              :id="`sub-group-${indexOneLevel}-${indexTwoLevel}`"
              type="checkbox"
              hidden />
            <label :for="`sub-group-${indexOneLevel}-${indexTwoLevel}`">
              <span
                v-if="itemTwoLevel.options && itemTwoLevel.options.length"
                class="fa fa-angle-right">
              </span>
              {{ itemTwoLevel.name }}
            </label>
            <ul
              v-if="itemTwoLevel.options && itemTwoLevel.options.length"
              :class="`sub-group-${indexOneLevel}-${indexTwoLevel}__list sub-group-list`">
              <!-- <li>
                <label @click="selectAllReports(itemTwoLevel.options)">
                  Ver todo
                </label>
              </li> -->
              <li
                v-for="(itemThreeLevel, indexThreeLevel) in itemTwoLevel.options"
                :key="indexThreeLevel">
                <input
                  :id="`sub-sub-group-${indexOneLevel}-${indexTwoLevel}-${indexThreeLevel}`"
                  type="checkbox"
                  hidden />
                <label
                  :for="`sub-sub-group-${indexOneLevel}-${indexTwoLevel}-${indexThreeLevel}`"
                  :class="{'selected-report': idCurrentReport === itemThreeLevel.id}"
                  @click="selectReport(itemThreeLevel)">
                  <span
                    v-if="itemThreeLevel.options && itemThreeLevel.options.length"
                    class="fa fa-angle-right">
                  </span>
                  {{ itemThreeLevel.name }}
                </label>
                <ul
                  v-if="itemThreeLevel.options && itemThreeLevel.options.length"
                  :class="`sub-sub-group-${indexOneLevel}-${indexTwoLevel}-${indexThreeLevel}__list sub-sub-group-list`">
                  <li><a href="#">3rd level nav item</a></li>
                  <li><a href="#">3rd level nav item</a></li>
                  <li><a href="#">3rd level nav item</a></li>
                </ul>
              </li>
            </ul>
          </li>
        </ul>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  name: 'MenuReports',
  props: {
    active: {
      type: Boolean,
      default: true,
      required: true
    },
    oneLevel: {
      type: Array,
      default: () => [],
      required: true
    },
    idCurrentReport: {
      type: Number,
      default: 0
    }
  },
  methods: {
    closeAll () {
      const accordions = document.querySelectorAll('.accordion')
      accordions.forEach((accordion) => {
        const content = accordion.childNodes[1]
        this.closeAccordion(accordion, content)
      })
    },
    selectReport (report) {
      this.$emit('selectReport', [report])
    },
    selectAllReports (reports) {
      this.$emit('selectAllReport', reports)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../scss/variables';

.menu-reports {
  font-family: 'Montserrat', sans-serif;
  font-size: 15px;
  border-radius: 8px;
  overflow: hidden;
  width: 320px;
  opacity: 0;
  transition: .2s;
  z-index: -1;
  border: 1px solid #ececd6;

  &--active {
    z-index: 10;
    opacity: 1;
  }
}

ul {
  list-style: none;
  padding-left: 0;
}

.nav__list {
  border-radius: 8px;
  overflow: hidden;
  margin: 0;

  > li {
    border-bottom: 1px solid #ececd6;
  }
}

.nav__list label {
  display: flex;
  align-items: center;
}

.nav a, .nav label {
  display: flex;
  font-weight: 500;
  padding: 12px 20px;
  color: #004884;
  background-color: #fff;
  -webkit-transition: all .25s ease-in;
  transition: all .25s ease-in;
}

.nav label { cursor: pointer; }

/**
 * Styling first level lists items
 */

.group-list a,
.group-list label {
  font-weight: 500;
  padding-left: 40px;
  background: #f6f8f9;
  color: #004884;
}

/**
 * Styling second level list items
 */

.sub-group-list a, .sub-group-list label {
  padding-left: 60px;
  background: #fff;
  color: #004884;
  font-size: 14px;
  font-weight: 600;

  &.selected-report {
    background: #004884;
    color: #fff;
  }
}

.sub-group-list a:focus,
.sub-group-list a:hover,
.sub-group-list label:focus,
.sub-group-list label:hover {
  background: #e6effd;
  color: #004884;
}

/**
 * Styling third level list items
 */

.sub-sub-group-list a, .sub-sub-group-list label {
  padding-left: 80px;
  background: #454545;
}

.sub-sub-group-list a:focus, .sub-sub-group-list a:hover, .sub-sub-group-list label:focus, .sub-sub-group-list label:hover { background: #333333; }

/**
 * Hide nested lists
 */

.group-list, .sub-group-list, .sub-sub-group-list {
  height: 100%;
  max-height: 0;
  overflow: hidden;
  -webkit-transition: max-height .5s ease-in-out;
  transition: max-height .5s ease-in-out;
}

.nav__list input[type=checkbox]:checked + label + ul { /* reset the height when checkbox is checked */
  max-height: initial;
}

label > span {
  -webkit-transition: -webkit-transform .4s ease;
  transition: transform .4s ease;
  font-size: 24px;
  margin: 0;
  margin-right: 14px;
  position: relative;
  top: -1px;
}

.nav__list input[type=checkbox]:checked + label > span {
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}

</style>
