/* eslint-disable camelcase */
import Repository from '../repositories/RepositoryFactory'

const AuthRepository = Repository.get('auth')
const ApiRepository = Repository.get('api')
const ApiPublicRepository = Repository.get('public')

export default {
  async login ({ commit }) {
    try {
      commit('setIsLoading', true)
      const response = await AuthRepository.login()
      /* eslint camelcase: "error" */
      const { login_url: loginUrl } = response.data
      commit('setLoginUrl', { url: loginUrl })
      return response
    } catch (error) {
      commit('setGlobalError', { error })
      return error
    }
  },
  async getTokenLoginWithCode ({ commit, dispatch, state }) {
    try {
      commit('setIsLoading', true)
      const { state: value, code } = state.loginUrl
      const response = await AuthRepository.getTokenLoginWithCode({ state: value, code })
      localStorage.setItem('token', response.data.token)
      dispatch('getUserProfile')
      dispatch('getReports')
      dispatch('sendStatsLogin')

      return response
    } catch (error) {
      commit('setGlobalError', { error })
      return error
    }
  },
  async getUserProfile ({ commit }) {
    try {
      const response = await ApiRepository.getUserProfile()
      commit('setUserSession', response.data)
      commit('setIsLogged', true)

      return response.data
    } catch (error) {
      return error
    } finally {
      commit('setIsLoading', false)
    }
  },
  async getReports ({ commit }) {
    try {
      commit('setReports', { isLoading: true })
      const response = await ApiRepository.getReports()
      const { data } = response
      commit('setReports', { data })
      return data
    } catch (error) {
      return error
    } finally {
      commit('setReports', { isLoading: false })
    }
  },
  async getPublicReports ({ commit }) {
    try {
      commit('setPublicReports', { isLoading: true })
      const response = await ApiPublicRepository.getReports()
      const { data } = response

      commit('setPublicReports', { data: data })
      return data
    } catch (error) {
      return error
    } finally {
      commit('setPublicReports', { isLoading: false })
    }
  },
  async sendStatsLogin () {
    try {
      ApiRepository.sendStatsLogin()
    } catch (error) {
      console.log(error)
      throw error
    }
  },
  async sendStatsPublicReport ({ state }) {
    try {
      ApiRepository.sendStatsPublicReport({
        report_id: state.currentPublicReports.data[0]?.id,
        clicks: 1
      })
    } catch (error) {
      console.log(error)
      throw error
    }
  },
  async sendStatsPrivateReport ({ state }) {
    try {
      ApiRepository.sendStatsPrivateReport({
        report_id: state.currentReports.data[0]?.id,
        clicks: 1
      })
    } catch (error) {
      console.log(error)
      throw error
    }
  },
  async sendStatsCrossReport ({ state }) {
    try {
      const reportId = state.currentPublicReports.data || state.currentReports.data
      ApiRepository.sendStatsCrossReport({
        report_id: reportId[0].id,
        clicks: 1
      })
    } catch (error) {
      console.log(error)
      throw error
    }
  },
  async sendAccessPublicReport ({ state }) {
    try {
      ApiRepository.sendStatsPublicReport({
        report_id: state.currentPublicReports.data[0]?.id
      })
    } catch (error) {
      console.log(error)
      throw error
    }
  },
  async sendAccessPrivateReport ({ state }) {
    try {
      ApiRepository.sendStatsPrivateReport({
        report_id: state.currentReports.data[0]?.id
      })
    } catch (error) {
      console.log(error)
      throw error
    }
  },
  async sendAccessCrossReport ({ state }) {
    try {
      const reportId = state.currentPublicReports.data || state.currentReports.data
      ApiRepository.sendStatsCrossReport({
        report_id: reportId[0].id
      })
    } catch (error) {
      console.log(error)
      throw error
    }
  }
}
