<template>
  <header class="header">
    <div class="header__wrap content-xl">
      <div class="header__brand">
        <a href="https://www.gov.co/home/">
          <img
            alt="Picture"
            class="img-fluid"
            id="bloqueImg3"
            width="99"
            height="20"
            src="../assets/logo-gov-co.png">
        </a>
      </div>
      <div class="header__options">
        <a
          v-if="!isLogged && !isLoading"
          rel="noopener noreferrer"
          @click="login">
          Login
        </a>
        <div
          v-if="isLogged"
          class="header__info">
          <p v-if="userSession">{{ userSession.username }}</p>
          <a
            href=""
            rel="noopener noreferrer"
            @click="logout">
            Logout
          </a>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'Header',
  computed: {
    ...mapState(['isLogged', 'userSession', 'isLoading'])
  },
  methods: {
    async login () {
      await this.$store.dispatch('login')
      window.location.href = this.$store.state.loginUrl.url
    },
    logout () {
      localStorage.clear()
    }
  }
}
</script>

<style scoped lang="scss">
@import '../scss/variables';

.header {
  background: $primary-color;
  color: #fff;
  padding: 10px 0;

  &__wrap {
    display: flex;
    justify-content: space-between;
  }

  &__title {
    font-weight: 600;
  }

  &__options {
    display: flex;
    align-items: center;

    a {
      color: #fff;
      cursor: pointer;
    }
  }

  &__info {
    display: flex;
    align-items: center;

    p {
      margin: 0 20px;
    }
  }
}
</style>
